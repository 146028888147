






















import Vue, { PropType } from "vue";
import { ErrorDetail } from "@/api";
import { ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
function zenkakuLength(str: string): number {
  let sum = 0;
  [...str].forEach(char => (sum += char.match(/[ -~]/) ? 0.5 : 1));
  return sum;
}
function hasZenkaku(str: string): boolean {
  for (let i = 0 ; i < str.length; i++) {
    if (str[i] === "@") {
      continue;
    }
    if (!str[i].match(/[ -~]/)) {
      return true;
    }
  }
  return false;
}
function emailLength(str: string, length: number): boolean {
  const localName = str.split("@");
  return localName[0].length >= length;
}
extend("max_zenkaku", {
  validate(value, args) {
    return zenkakuLength(value) <= parseInt(args.length);
  },
  params: ["length"],
  message: "全角22字以内、半角44字以内で入力してください",
});
extend("no_double_byte", {
  validate(value) {
    return hasZenkaku(value) === false
  },
  message:"メールアドレスは有効なメールアドレスではありません"
});
extend("email_length", {
  validate(value, args) {
    return emailLength(value, parseInt(args.length));
  },
  params: ["length"],
  message: "メールアドレスは有効なメールアドレスではありません"
})
extend("email", { ...email, message: "メールアドレスは有効なメールアドレスではありません" });
extend("required", { ...required, message: `{_field_}は必須項目です` });
extend("passwordLength", {
  validate(value) {
    return value.length >= 8;
  },
  message: "8字以上で入力してください",
});
extend("passwordCharacterSet", {
  validate(value) {
    const hasNumber: boolean = /\d/.test(value);
    const hasUpperCase: boolean = /[A-Z]/.test(value);
    const hasLowerCase: boolean = /[a-z]/.test(value);

    return hasNumber && hasUpperCase && hasLowerCase;
  },
  message: "半角 英大文字・小文字・数字を含めて入力してください",
});

export type DataType = {
  focused: boolean;
};

export default Vue.extend({
  name: "BaseInputForm",
  components: {
    ValidationProvider,
  },

  props: {
    disabled: Boolean as PropType<boolean>,
    resource: String as PropType<string>,
    propkey: String as PropType<string>,
    label: String as PropType<string>,
    placeholder: String as PropType<string>,
    value: String as PropType<string>,
    password: Boolean as PropType<boolean>,
    required: { type: Boolean as PropType<boolean>, default: false },
    type: { type: String as PropType<string>, default: "text" },
    rules: { type: String as PropType<string>, default: "" },
  },

  data(): DataType {
    return {
      focused: false,
    };
  },

  computed: {
    bindValue: {
      get(): string {
        return this.value;
      },

      set(value: string): void {
        this.$emit("input", value);
      },
    },

    hasValidationError(): boolean {
      if (this.$error.errDetails === undefined) return false;
      return this.$error.errDetails.some((ed: ErrorDetail) => {
        return ed.resource === this.resource && ed.field === this.propkey;
      });
    },

    validationErrorMsg(): string | undefined {
      if (this.$error.errDetails === undefined) return;

      return this.$error.errDetails
          .filter((ed: ErrorDetail) => {
            return ed.resource === this.resource && ed.field === this.propkey;
          })
          .map((ed: ErrorDetail) => ed.message)
          .join(", ");
    },

    formClasses(): string[] {
      const res: string[] = [];
      if (this.hasValidationError) res.push("has-err");
      if (this.disabled) res.push("disabled");
      return res;
    },
  },

  methods: {
    changeFocused(): void {
      this.focused = !this.focused;
    },
    enterPressed(): void {
      this.$emit("enterPressed");
    },
  },
});
