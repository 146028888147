

















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BaseSubmitButton',

  props: {
    label: String as PropType<string>,
    disabled: Boolean as PropType<boolean>,
    loading: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined
    },
    checked: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined
    },
  },

  computed: {
    _loading(): boolean {
      if (this.loading === undefined || this.loading === null) {
        return this.$globalData.loading
      }
      return this.loading
    },

    _checked(): boolean {
      if (this.checked === undefined || this.checked === null) {
        return this.$globalData.checked
      }
      return this.checked
    },

    processing(): boolean {
      return this._loading || this._checked
    }
  },

  methods: {
    emitClick(): void {
      if (this.processing || this.disabled) return
      this.$emit('click')
    }
  },
})
