import { render, staticRenderFns } from "./BaseInputForm.vue?vue&type=template&id=159f5770&scoped=true&lang=pug&"
import script from "./BaseInputForm.vue?vue&type=script&lang=ts&"
export * from "./BaseInputForm.vue?vue&type=script&lang=ts&"
import style0 from "./BaseInputForm.vue?vue&type=style&index=0&id=159f5770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159f5770",
  null
  
)

export default component.exports